<template>
  <div class="list">
    <div class="tab">
      <div class="left">
        <el-button type="primary" @click="additem">新增房源</el-button>
      </div>
      <div class="right">
        <el-button v-for="(item, key) in tab" :key="key" @click="tabbtn(item, key)"
          :class="{ active: tabtype == item.value }">{{ item.title }}（{{ item.num }} ）</el-button>
      </div>
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-autocomplete v-model="schoolname" :fetch-suggestions="schoolSearch" placeholder="学校名(海高、海本)" :debounce="500"
            @select="schoolSelect" :popper-append-to-body="false" @input="schoolChange">
            <template slot-scope="{ item }">
              <div class="zw">{{ item.zwmc }}</div>
              <span class="yw">{{ item.name }}</span>
            </template>
          </el-autocomplete>
        </el-col>
        <el-col :span="3">
          <el-autocomplete v-model="cityname" :fetch-suggestions="citySearch" placeholder="城市名" value-key="csyw"
            :debounce="500" @select="citySelect" :popper-append-to-body="false" @input="cityChange">
            <template slot-scope="{ item }">
              <div class="zw">{{ item.csyw }}</div>
              <span class="yw">{{ item.name }}</span>
            </template>
          </el-autocomplete>
        </el-col>
        <el-col :span="3">
          <el-input v-model="form.name" placeholder="住家名"></el-input>
        </el-col>
        <el-col :span="2">
          <el-input v-model="form.mail" placeholder="邮箱"></el-input>
        </el-col>
        <el-col :span="2">
          <el-input v-model="form.longitude" placeholder="经度"></el-input>
        </el-col>
        <el-col :span="2">
          <el-input v-model="form.latitude" placeholder="纬度"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.ethnicity" placeholder="族裔">
            <el-option v-for="item in options.ethnicity" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="width10" style="width:100%;height:10px;"></div>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="form.price" placeholder="价格范围">
            <el-option v-for="item in options.price" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.pet" placeholder="宠物情况">
            <el-option v-for="item in options.pet" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.religion" placeholder="宗教情况">
            <el-option v-for="item in options.religion" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="screen">筛选</el-button>
        </el-col>
        <el-col :span="3">
          <el-button @click="reset">显示全部</el-button>
        </el-col>

        <!-- <el-col :span="3">
          <el-button @click="dialogFormVisible = true">发送短信提醒</el-button>
        </el-col> -->
        <el-col :span="3">
          <el-button @click="dialogFormVisible = true">发送邮件提醒</el-button>
        </el-col>

      </el-row>
    </div>
    <Table :list="list" v-loading="listloading" :schoolname="schoolname" />
    <div class="fy">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>


    <el-dialog title="发送短信&邮件" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="发送内容">
          <el-input v-model="textarea" autocomplete="off" type="textarea" placeholder="请输入内容"
            :autosize="{ minRows: 6, maxRows: 8 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Cancel()">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "./table/table";
import { Count, List, School, City, SendEmail } from "@/api/list";
import { createLogger } from 'vuex';
export default {
  components: { Table },
  data() {
    return {
      ids: "all",
      types: "all",
      textarea: '',
      dialogFormVisible: false,
      tabtype: "",
      tab: [
        {
          title: "全部数据",
          value: "",
          num: 14200,
        },
        // {
        //   title: "待审核",
        //   value: "待审核",
        //   num: 0,
        // },
        // {
        //   title: "可用",
        //   value: "可租",
        //   num: 0,
        // },
        // {
        //   title: "已入住",
        //   value: "已租",
        //   num: 0,
        // },
        // {
        //   title: "停用",
        //   value: "已下架",
        //   num: 0,
        // },
      ],
      schoolname: "",
      cityname: "",
      form: {
        school: "", //学校ID
        city: "", //城市
        name: "", //住家名，房东名
        mail: "", //邮箱
        longitude: "", //经度
        latitude: "", //纬度
        ethnicity: "", //族裔
        price: "", //价格
        pet: "", //宠物
        religion: "", //宗教
      },
      options: {
        ethnicity: [
          {
            value: "中国",
            label: "中国",
          },
          {
            value: "中国+美国",
            label: "中国+美国",
          },
          {
            value: "韩国",
            label: "韩国",
          },
          {
            value: "韩国+美国",
            label: "韩国+美国",
          },
          {
            value: "白人",
            label: "白人",
          },
          {
            value: "西班牙/拉丁裔",
            label: "西班牙/拉丁裔",
          },
          {
            value: "非裔",
            label: "非裔",
          },
          {
            value: "其他亚洲/太平洋岛民",
            label: "其他亚洲/太平洋岛民",
          },
          {
            value: "亚裔",
            label: "亚裔",
          },
          {
            value: "未知",
            label: "未知",
          },
        ],
        price: [
          {
            value: "1",
            label: "1000以下",
          },
          {
            value: "2",
            label: "1000-1500",
          },
          {
            value: "3",
            label: "1500以上",
          },
        ],
        pet: [
          {
            value: 1,
            label: "有宠物",
          },
          {
            value: 0,
            label: "无宠物",
          },
        ],
        religion: [
          {
            value: "佛教",
            label: "佛教",
          },
          {
            value: "印度教",
            label: "印度教",
          },
          {
            value: "天主教",
            label: "天主教",
          },
          {
            value: "基督教",
            label: "基督教",
          },
          {
            value: "犹太教",
            label: "犹太教",
          },
          {
            value: "伊斯兰教",
            label: "伊斯兰教",
          },
          {
            value: "其他",
            label: "其他",
          },
          {
            value: "无宗教",
            label: "无宗教",
          },
        ],
      },
      list: [],
      listloading: false,
      timeout: null,
      page: 1,
      limit: 10,
      total: 0, //总数
    };
  },
  mounted() {
    this.getCount();
    this.getList(999);
  },
  methods: {
    determine(e) {

      SendEmail({ file: "", content: this.textarea, type: this.types, ids: this.ids }).then(res => {
        // console.log(res, 222) 
        if (res.code == 200) {
          this.$message({
            message: '恭喜你，邮件发送成功', 
            type: 'success'
          });
        }
        if (res.code == 500) {
          this.$message({
            message: '系统繁忙，快找相关研发人员汇报',
            type: 'error'
          });
        }
      });
      this.dialogFormVisible = !this.dialogFormVisible;
      this.textarea = "";
    },

    Cancel(e) {
      this.dialogFormVisible = !this.dialogFormVisible;
      this.textarea = "";
    },
// 192.168.1.169
    cityChange(e) {
      if (e == "") {
        this.form.city = "";
      }
    },
    schoolChange(e) {
      if (e == "") {
        this.form.school = "";
      }
    },
    //新增
    additem() {
      let routeUrl = this.$router.resolve({
        path: "/layouts/addlist",
      });
      window.open(routeUrl.href, "_blank");
    },
    //学校搜索
    schoolSearch(queryString, cb) {
      var restaurants;
      School({
        schoolname: queryString,
      }).then((res) => {
        restaurants = res.data;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(restaurants);
        }, 300 * Math.random());
      });
    },
    //学校选中
    schoolSelect(item) {
      if (item.zwmc) {
        this.schoolname = item.zwmc;
      } else {
        this.schoolname = item.name;
      }
      this.form.school = item.id;
    },
    //城市搜索
    citySearch(queryString, cb) {
      var restaurants;
      City({
        cityname: queryString,
      }).then((res) => {
        restaurants = res.data;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(restaurants);
        }, 300 * Math.random());
      });
    },
    //城市选中
    citySelect(item) {
      if (item.csyw) {
        this.cityname = item.csyw;
      } else {
        this.cityname = item.name;
      }
      this.form.city = item.id;
    },
    //获取列表数据
    getList(id) {
      id == 999 ? this.types = 'all' : this.types = '';
      console.log(this.types, 999)
      this.listloading = true;
      const form = this.form;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        List({
          status: this.tabtype, //房源状态 可租 待审核
          schoolid: form.school, //学校id
          cityid: form.city, //城市id
          js_mail: form.mail, //邮箱
          name: form.name, //房东名，住家名
          longitude: form.longitude, //经度
          latitude: form.latitude, //纬度
          race: form.ethnicity, //族裔
          price: form.price, //价格
          pet: form.pet, //宠物
          beliefs: form.religion, //宗教
          page: this.page,
          count: this.limit,
        }).then((res) => {
          this.listloading = false;
          let { data, total, per_page, ids } = res.data;
          this.list = data;

          id == 999 ? this.ids = "all" : this.ids = ids;
          this.total = total;
          this.limit = per_page;
        });
      }, 500);
    },
    //获取各状态数量
    getCount() {
      Count().then((res) => {
        // this.tab[0].num = res.data.toal;
        // this.tab[1].num = res.data.dai;
        // this.tab[2].num = res.data.ke;
        // this.tab[3].num = res.data.yi;
        // this.tab[4].num = res.data.ting;
      });
    },
    //tab切换
    tabbtn(item, key) {
      this.tabtype = item.value;
      this.reset();
    },
    // 重置请求
    reset() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = "";
      });
      (this.schoolname = ""), (this.cityname = ""), (this.page = 1);
      this.getList(999);
    },
    //筛选
    screen() {
      this.page = 1;
      this.getList();
    },
    //切换每页条数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.reset();
    },
    //切换页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;

  /deep/ .el-button {
    border-radius: 30px;
    padding: 12px 30px;
  }

  .active {
    color: white;
    background: #697b8a;
  }
}

.form {
  margin-bottom: 33px;

  /deep/ .el-button,
  .el-input,
  .el-autocomplete {
    width: 100%;
  }

  /deep/ .el-autocomplete-suggestion__wrap {
    li {
      line-height: normal;
      padding: 7px;

      .zw {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .yw {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .zw {
        color: #ddd;
      }
    }
  }
}

// /deep/ input::-webkit-input-placeholder {
//   color: #f3f3f3 !important;
// }
// /deep/ input::-moz-input-placeholder {
//   color: #f3f3f3 !important;
// }
// /deep/ input::-ms-input-placeholder {
//   color: #f3f3f3 !important;
// }
.fy {
  text-align: center;
  padding: 40px 0px;
}
</style>
