import request from '@/utils/axios'
// 列表数量
export function Count (params) {
  return request({
    url: '/api/getCount',
    method: 'get',
    params
  })
}
// 列表搜索
export function List (data) {
  return request({
    url: '/api/getSearch',
    method: 'post',
    data
  })
}
// 学校
export function School (params) {
  return request({
    url: '/api/getSchool',
    method: 'get',
    params
  })
}
// 城市
export function City (params) {
  return request({
    url: '/api/getCity',
    method: 'get',
    params
  })
}
// 删除
export function Delete (params) {
  return request({
    url: '/api/deleteHouse',
    method: 'get',
    params
  })
}
// 改变房源状态
export function Status (params) {
  return request({
    url: '/api/updateStatus',
    method: 'get',
    params
  })
}

// 批量发邮件
export function SendEmail (params) {
  return request({
    url: '/api/sendEmail',
    method: 'post',
    params
  })
}