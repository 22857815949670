<template>
  <div class="tableList">
    <div class="list" v-for="(item, key) in list" :key="key">
      <el-row
        type="flex"
        class="row-bg top"
        justify="space-between"
        align="middle"
      >
        <el-col :span="4"> 编号：{{ item.id }} </el-col>
        <el-col :span="4"> 更新时间：{{ item.lastmodifydate }} </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg bottom"
        justify="space-between"
        align="middle"
      >
        <!-- 信息info_box -->
        <el-col :span="7" class="info_box">
          <div class="img">
            <!-- <img src="./img/gongyu.png" v-if="item.js_type == '公寓'" />
            <img
              src="./img/lianpai.png"
              v-else-if="item.js_type == '联排别墅'"
            />
            <img src="./img/dudong.png" v-else /> -->
            <img :src="item.cover_img" alt="">
          </div>
          <div class="info">
            <p>
              {{ item.name }} {{ item.js_sex }} / {{ item.age }}岁 /
              {{ item.js_education }} / {{ item.js_occupation }}
            </p>
            <div>
              <span v-if="item.js_race"> 族裔：{{ item.js_race }} </span>
              <span v-if="item.js_jlzt"> 身份：{{ item.js_jlzt }} </span>
              <span v-if="item.js_marriage">
                婚姻：{{ item.js_marriage }}
              </span>
              <span v-if="item.js_beliefs"> 宗教：{{ item.js_beliefs }} </span>
              <span v-if="item.js_experience">
                经验：{{ item.js_experience }}
              </span>
              <span v-if="item.pet"> 宠物：{{ item.pet }} </span>
            </div>
          </div>
        </el-col>
        <!-- 完整度 -->
        <el-col :span="3" class="position">
          <span style="color:#2F2F32;font-size:14px">完整度</span>
          <el-progress :percentage="Number(item.complete)" :stroke-width="10" :color="customColor"></el-progress>
        </el-col>
        <!-- 位置position -->
        <el-col :span="3" class="position">
          <img src="@/assets/img/position.png" alt="" />
          <p v-if="item.chengshi">
            <span v-if="item.chengshi.csyw">{{ item.chengshi.csyw }}</span>
            <span v-if="item.chengshi.name">{{ item.chengshi.name }}</span>
          </p>
        </el-col>
        <!-- 距离 -->
        <el-col  v-if="item.distance" :span="3" class="position">
          <img src="@/assets/img/km.png" alt="" />
          <p>
            <span>{{ item.distance.toFixed(2) }}/km</span>
          </p>
        </el-col>
        <!-- 状态type -->
        <el-col :span="3" class="type">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <div :class="{ kz: item.house_status == '可租' }" class="a">
                {{ item.house_status
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="commandValue('可租', item.id, key)"
                >可租</el-dropdown-item
              >
              <el-dropdown-item :command="commandValue('已租', item.id, key)"
                >已租</el-dropdown-item
              >
              <el-dropdown-item :command="commandValue('已下架', item.id, key)"
                >已下架</el-dropdown-item
              >
              <el-dropdown-item :command="commandValue('待审核', item.id, key)"
                >待审核</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <!-- 邮箱mail -->
        <el-col :span="3" class="mail">
          <el-tooltip
            class="item"
            effect="light"
            v-if="item.js_mail"
            :content="item.js_mail"
            placement="top-start"
          >
            <img src="@/assets/img/mail.png" alt="" />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            v-if="item.js_phone_one"
            :content="item.js_phone_one"
            placement="top-start"
          >
            <img src="@/assets/img/phone.png" alt="" />
          </el-tooltip>
          <img
            v-if="!item.js_phone_one && !item.js_mail"
            src="@/assets/img/nophone.png"
            alt=""
          />
        </el-col>
        <!-- 备注remark -->
        <el-col :span="3" class="remark">
          <div>
            <span>备注：</span>
            <p :title="item.js_remarks">{{ item.js_remarks }}</p>
          </div>
        </el-col>
        <el-col :span="3" class="h5">
          <img src="@/assets/img/h5.png" alt="" @click="h5(item, key)" />
        </el-col>
        <el-col :span="2" class="operate">
          <el-button @click="setItem(item, key)"
            ><img src="@/assets/img/edit.png" alt="" />编辑</el-button
          >
          <el-button @click="deleteItem(item, key)"
            ><img src="@/assets/img/delete.png" alt="" />删除</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 空数据 -->
    <el-empty v-if="list.length <= 0" description="暂无数据"></el-empty>
    <!-- 删除操作提示 -->
    <el-dialog title="删除提示" :visible.sync="dialogVisible" width="30%">
      <span>确认删除该房源吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ondel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Delete, Status } from "@/api/list";
export default {
  props: ["list", "listloading", "schoolname"],
  data() {
    return {
      checked: true,
      dialogVisible: false, //删除提示展示
      delId: "", //删除要用的id
      delIndex: "", //删除要用的下标
      customColor: '#F7C30E', // 进度条颜色
    };
  },
  methods: {
    commandValue(type, id, key) {
      return {
        type: type,
        id: id,
        key: key,
      };
    },
    handleCommand(e) {
      console.log(e);

      Status({
        house_status: e.type,
        id: e.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.list[e.key].house_status = e.type
        }
      });
    },
    //跳转h5移动端
    h5(item, key) {
      console.log(this.schoolname);
      console.log(item.distance);
      let distance;
      if (item.distance && this.schoolname != "") {
        distance = item.distance.toFixed(2);
        let routeUrl = this.$router.resolve({
          path: "/houseDetail",
          query: {
            id: item.id,
            schoolname: this.schoolname,
            distance,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        let routeUrl = this.$router.resolve({
          path: "/houseDetail",
          query: { id: item.id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    setItem(item, key) {
      let routeUrl = this.$router.resolve({
        path: "/layouts/addlist",
        query: { id: item.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    deleteItem(item, key) {
      this.dialogVisible = true;
      this.delId = item.id;
      this.delIndex = key;
    },
    ondel() {
      console.log(this.delId);
      console.log(this.list);
      Delete({
        id: this.delId,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.list.splice(this.delIndex, 1);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-progress{
  width: 80%;
  text-align: center;
  margin: 10px 0 0 0;
}
::v-deep .el-progress__text{
  color: #2F2F32;
}
.tableList {
  background: #fafafa;
  .list {
    height: 190px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;
    .top {
      padding: 0 20px;
      height: 34px;
      border-bottom: 1px solid #f9f9f9;
      font-size: 12px;
      font-weight: 400;
      color: #6d6d6d;
      .el-col:nth-of-type(2) {
        text-align: right;
      }
    }
    .bottom {
      padding: 0 20px;
      height: 154px;
      .el-col {
        height: 102px;
        border-left: 1px solid #e4e4e4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .el-col:nth-of-type(1) {
        border: none;
      }
      .info_box {
        flex-shrink: 0;
        .img {
          width: 107px;
          height: 107px;
          margin-right: 23px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }
        .info {
          flex: 1;
          padding-right: 10px;
          p {
            font-size: 18px;
            font-weight: 500;
            color: #1e1e1e;
            margin-bottom: 10px;
          }
          > div {
            font-size: 14px;
            font-weight: 400;
            color: #6d6d6d;
            line-height: 24px;
          }
        }
      }
      .position {
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #6d6d6d;
        > img {
          height: 28px;
          margin-bottom: 16px;
        }
      }
      .type {
        /deep/.el-dropdown {
          width: 104px;
          height: 30px;

          font-size: 14px;
          font-weight: 400;
          color: #697b8a;
          text-align: center;
          line-height: 30px;
        }
        .a {
          border-radius: 21px;
          border: 1px solid #dde3e8;
          cursor: pointer;
        }
        .kz {
          border: 1px solid #ceeae0;
          color: #079562;
        }
      }
      .mail {
        img {
          height: 40px;
          margin: 0 12px;
        }
      }
      .remark {
        > div {
          font-size: 14px;
          font-weight: 400;
          color: #1e1e1e;
          > span {
            display: block;
            text-align: center;
            color: #6d6d6d;
            margin-bottom: 10px;
            display: block;
          }
          > p {
            width: 80%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /*! autoprefixer: ignore next */
            -webkit-box-orient: vertical;
            margin: auto;
          }
        }
      }
      .h5 {
        > img {
          height: 40px;
          cursor: pointer;
        }
      }
      .operate {
        flex-direction: column;
        .el-button {
          width: 79px;
          height: 28px;
          border-radius: 21px;
          font-size: 12px;
          font-weight: 400;
          padding: 0px;
          margin: 8px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 12px;
            margin-right: 4px;
          }
          &:nth-of-type(1) {
            border-color: #079562;
            color: #079562;
          }
          &:nth-of-type(2) {
            color: #ee4a4a;
            border-color: #ee4a4a;
            &:hover {
              background: rgba(238, 74, 74, 0.1);
            }
            &:focus {
              background: rgba(238, 74, 74, 0.1);
            }
          }
        }
      }
    }
  }
}
</style>
